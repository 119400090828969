import { useEffect, useState } from 'react';

import {
  Box,
  CircularProgress,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
  Button,
  Card,
  CardContent,
  Divider,
} from '@mui/material';
import {
  useGetLorasDataMutation,
  useGetOrdersDataMutation,
  useGetRevenuecatMutation,
  useGetSubscriptionUsageMutation,
  useGetUsersMutation,
} from 'api/apiSlice';

import Pie from './Pie';
import Block from './Block';
import { LineChart } from '@mui/x-charts/LineChart';
import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge';
import { BarChart } from '@mui/x-charts/BarChart';

import Dropdown from 'components/Dropdown';
import DatePicker from 'components/DatePicker';
import dayjs from 'dayjs';
import DataGridUsers from './Datagrid';

const Analytics = () => {
  const [loading, setloading] = useState(true);

  const [fetchingLoras, setfetchingLoras] = useState(false);
  const [fetchingUsers, setfetchingUsers] = useState(false);
  const [fetchingSubscription, setfetchingSubscription] = useState(false);

  const [ordersTotal, setordersTotal] = useState(0);
  const [ordersData, setordersData] = useState();
  const [lorasData, setlorasData] = useState({
    species: [],
    gender: [],
    sex: [],
  });
  const [usersData, setusersData] = useState({});
  const [usersOverTime, setusersOverTime] = useState([]);
  const [subscriptionData, setsubscriptionData] = useState([]);

  const [getOrdersData] = useGetOrdersDataMutation();
  const [getLorasData] = useGetLorasDataMutation();
  const [getUsersData] = useGetUsersMutation();
  const [getSubscriptionUsage] = useGetRevenuecatMutation();

  const [queryFrom, setQueryFrom] = useState(() =>
    dayjs(new Date(1989, 3, 28))
  );
  const [queryTo, setQueryTo] = useState(() => dayjs(new Date()));

  useEffect(() => {
    fetch([0, Math.floor(Date.now() / 1000)]);
    let loras = sessionStorage.getItem('loras_data');
    if (loras) {
      setlorasData(JSON.parse(loras));
    }
    let users = sessionStorage.getItem('users_data');
    if (users) {
      setusersData(JSON.parse(users));
    }
    let users_over_time = sessionStorage.getItem('users_over_time');
    if (users_over_time) {
      setusersOverTime(JSON.parse(users_over_time));
    }
    fetchSubscriptionData();
  }, []);

  useEffect(() => {
    if (ordersData) {
      setloading(false);
    }
  }, [ordersData]);

  const handleFetch = async () => {
    const from = Math.floor(
      queryFrom.startOf('day').add(1, 'second').valueOf() / 1000
    ); // Start of day + 1 second
    const to = Math.floor(queryTo.endOf('day').valueOf() / 1000);
    fetch([from, to]);
    fetchLorasData();
    fetchUsersData();
  };

  const fetch = async (ts) => {
    const orders = await getOrdersData(ts).unwrap();
    setordersTotal(orders.total);
    var parsed = orders.data
      .map((item, index) => {
        return {
          label: item.item,
          value: item.percentage.toFixed(2),
          count: item.count,
          id: index,
        };
      })
      .sort((a, b) => a.value - b.value);
    console.log(parsed);
    setordersData(parsed);
  };

  const fetchLorasData = async () => {
    const from = Math.floor(
      queryFrom.startOf('day').add(1, 'second').valueOf() / 1000
    ); // Start of day + 1 second
    const to = Math.floor(queryTo.endOf('day').valueOf() / 1000);
    setfetchingLoras(true);
    const data = await getLorasData([from, to]).unwrap();
    var species = Object.keys(data.species).map((key, index) => {
      return { id: index, label: key, value: data.species[key].toFixed(1) };
    });
    var gender = Object.keys(data.gender).map((key, index) => {
      return { id: index, label: key, value: data.gender[key].toFixed(1) };
    });
    var combined = Object.keys(data.sex).map((key, index) => {
      return { id: index, label: key, value: data.sex[key].toFixed(1) };
    });
    var temp = { species: species, gender: gender, sex: combined };
    setlorasData(temp);
    sessionStorage.setItem('loras_data', JSON.stringify(temp));
    console.log(temp);
  };

  useEffect(() => {
    Object.keys(lorasData).length !== 0 && setfetchingLoras(false);
  }, [lorasData]);

  const fetchUsersData = async () => {
    setfetchingUsers(true);
    var data = await getUsersData().unwrap();

    const from = queryFrom.startOf('day').add(1, 'second'); // Start of day + 1 second
    const to = queryTo.endOf('day');
    data = data.users.filter((user) => {
      const targetDate = dayjs(user.date);
      if (targetDate.isBetween(from, to)) {
        return user;
      }
    });
    console.log(data);
    const anonymous = data.filter((user) => user.email.includes('gleem.ai'));
    const activated = data.filter((user) => user.activated);
    const limbo =
      parseInt(data.length) -
      parseInt(anonymous.length) -
      parseInt(activated.length);
    const subscribers = data.filter((user) => user?.subscription?.active);
    const trial = data.filter((user) => !user?.trial.available);
    const paid = data.filter((user) => user?.transacted);

    var users = {
      total: data.length,
      anonymous: anonymous.length,
      activated: activated.length,
      limbo: limbo,
      subscribers: subscribers.length,
      trial_used: trial.length,
      paid: paid.length,
    };
    setusersData(users);
    sessionStorage.setItem('users_data', JSON.stringify(users));

    const created_at = data
      .map((user) => parseInt(user.date.split('-')[1]))
      .reduce((acc, item) => {
        acc[item] = (acc[item] || 0) + 1;
        return acc;
      }, {});
    const d = Object.keys(created_at).map((key) => {
      return { x: key, y: created_at[key] };
    });
    setusersOverTime(d);
    sessionStorage.setItem('users_over_time', JSON.stringify(d));
  };

  useEffect(() => {
    Object.keys(usersData) !== 0 && setfetchingUsers(false);
  }, [usersData]);

  const fetchSubscriptionData = async () => {
    const usage = await getSubscriptionUsage().unwrap();
    console.log(usage);
    setsubscriptionData(usage);
  };

  useEffect(() => {
    Object.keys(subscriptionData) !== 0 && setfetchingSubscription(false);
  }, [subscriptionData]);

  return (
    <>
      {loading ? (
        <CircularProgress />
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          padding="10px 20px"
          flexWrap="wrap"
        >
          <Box
            display="flex"
            flexDirection="row"
            gap="20px"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Typography
              sx={{ fontSize: '22px', width: 'auto', marginBottom: '10px' }}
            >
              Orders {ordersTotal}
            </Typography>
            <DatePicker
              selectedDate={queryFrom}
              setSelectedDate={setQueryFrom}
              label="from"
            />
            <DatePicker
              selectedDate={queryTo}
              setSelectedDate={setQueryTo}
              label="to"
            />
            <Button variant="contained" onClick={handleFetch}>
              FETCH
            </Button>
          </Box>

          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            flexWrap="wrap"
          >
            <Block title="Top 10 products" maxWidth="300px">
              <Pie
                data={ordersData
                  .filter((item) => item.label !== 'Trial Product')
                  .slice(ordersData.length - 10, ordersData.length)}
                size={{
                  width: 380,
                  height: 250,
                }}
              />
              <List
                sx={{
                  bgcolor: 'background.paper',
                  width: '280px',
                  paddingLeft: '20px',
                }}
              >
                {[
                  ...ordersData
                    .filter((item) => item.label !== 'Trial Product')
                    .slice(ordersData.length - 11, ordersData.length - 1),
                ]
                  .reverse()
                  .map((item) => {
                    return (
                      <ListItem>
                        <ListItemText primary={`${item.value}% `} />
                        <ListItemText secondary={item.label} />
                      </ListItem>
                    );
                  })}
              </List>
            </Block>
            <Block title="All products" maxWidth="500px">
              <List
                sx={{
                  width: '400px',
                  maxHeight: '600px',
                  bgcolor: 'background.paper',
                  overflowY: 'scroll',
                  '&::-webkit-scrollbar': {
                    width: '8px',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: '#888',
                    borderRadius: '4px',
                  },
                  '&::-webkit-scrollbar-thumb:hover': {
                    backgroundColor: '#555',
                  },
                  '&::-webkit-scrollbar-track': {
                    backgroundColor: 'background.paper',
                  },
                }}
              >
                {[...ordersData].reverse().map((item) => {
                  return (
                    <ListItem divider={true}>
                      <ListItemText
                        primary={`${item.value}% - ${item.count}`}
                      />
                      <ListItemText secondary={item.label} />
                    </ListItem>
                  );
                })}
              </List>
            </Block>
            <Block title="LORAs">
              <Box
                width="100%"
                display="flex"
                flexDirection="row"
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                {!fetchingLoras ? (
                  <>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <Typography>species</Typography>
                      <Pie
                        data={lorasData?.species}
                        size={{
                          width: 270,
                          height: 250,
                        }}
                      />
                      <List
                        sx={{
                          bgcolor: 'background.paper',
                          width: '180px',
                          paddingLeft: '20px',
                        }}
                      >
                        {[...lorasData?.species].reverse().map((item) => {
                          return (
                            <ListItem>
                              <ListItemText primary={`${item.value}%`} />
                              <ListItemText secondary={item.label} />
                            </ListItem>
                          );
                        })}
                      </List>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <Typography>gender</Typography>
                      <Pie
                        data={lorasData?.gender}
                        size={{
                          width: 270,
                          height: 250,
                        }}
                      />
                      <List
                        sx={{
                          bgcolor: 'background.paper',
                          width: '180px',
                          paddingLeft: '20px',
                        }}
                      >
                        {[...lorasData?.gender].reverse().map((item) => {
                          return (
                            <ListItem>
                              <ListItemText primary={`${item.value}%`} />
                              <ListItemText secondary={item.label} />
                            </ListItem>
                          );
                        })}
                      </List>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <Typography>combined</Typography>
                      <Pie
                        data={lorasData?.sex}
                        size={{
                          width: 270,
                          height: 250,
                        }}
                      />
                      <List
                        sx={{
                          bgcolor: 'background.paper',
                          width: '180px',
                          paddingLeft: '20px',
                        }}
                      >
                        {[...lorasData?.sex].reverse().map((item) => {
                          return (
                            <ListItem>
                              <ListItemText primary={`${item.value}%`} />
                              <ListItemText secondary={item.label} />
                            </ListItem>
                          );
                        })}
                      </List>
                    </Box>
                  </>
                ) : (
                  <>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <CircularProgress />
                      <Typography>fetching data...</Typography>
                    </Box>
                  </>
                )}
              </Box>
              <Button
                variant="contained"
                onClick={fetchLorasData}
                disabled={fetchingLoras}
              >
                FETCH
              </Button>
            </Block>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            gap="20px"
            marginBottom="10px"
            marginTop="30px"
          >
            <Typography
              sx={{
                fontSize: '22px',
              }}
            >
              Subscriptions
            </Typography>
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            width="100%"
            gap="10px"
            height="auto"
          >
            <Block title="overview">
              <Box
                padding="20px 20px"
                display="flex"
                flexDirection="row"
                flexWrap="wrap"
                justifyContent="space-between"
                sx={{
                  width: '100%',

                  margin: 'auto',
                }}
              >
                {subscriptionData?.metrics &&
                  subscriptionData.metrics
                    .filter((i) => i.value > 0)
                    .map((item) => {
                      return (
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="flex-start"
                          alignItems="center"
                          sx={{
                            flex: '1 1 calc(14% - 10px)', // Same as above
                            margin: '8px',
                            padding: '10px',
                            borderRadius: '4px',
                          }}
                        >
                          <Card sx={{ minWidth: 225 }}>
                            <CardContent>
                              <Typography
                                gutterBottom
                                sx={{ color: 'text.secondary', fontSize: 14 }}
                              >
                                {item.name}
                              </Typography>
                              <Divider horizontal />
                              <Typography variant="h2" component="div">
                                {item.unit === '#'
                                  ? item.value
                                  : `${item.unit}${item.value}`}
                              </Typography>
                              <Typography variant="h6" component="div">
                                {item.description}
                              </Typography>
                            </CardContent>
                          </Card>
                        </Box>
                      );
                    })}
              </Box>
            </Block>

            <DataGridUsers usage={subscriptionData} />
          </Box>

          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            gap="20px"
            marginBottom="10px"
            marginTop="30px"
          >
            <Typography
              sx={{
                fontSize: '22px',
              }}
            >
              Users
            </Typography>
            <Button
              variant="contained"
              onClick={fetchUsersData}
              disabled={fetchingUsers}
              sx={{ height: '40px' }}
            >
              FETCH
            </Button>
          </Box>
          {fetchingUsers ? (
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress />
              <Typography>fetching data...</Typography>
            </Box>
          ) : (
            Object.keys(usersData).length !== 0 && (
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="flex-start"
                width="100%"
                gap="10px"
                height="auto"
              >
                <Block title="overall stats">
                  <Box height="auto" width="100%">
                    {!fetchingUsers ? (
                      <>
                        <Box
                          padding="20px 20px"
                          display="flex"
                          flexDirection="row"
                          flexWrap="wrap"
                          justifyContent="space-between"
                          sx={{
                            width: '100%',
                            maxWidth: '700px',
                            margin: 'auto',
                          }}
                        >
                          {Object.keys(usersData).map((key) => {
                            return (
                              <Box
                                display="flex"
                                flexDirection="column"
                                justifyContent="flex-start"
                                alignItems="center"
                                sx={{
                                  flex: '1 1 1 calc(33% - 10px)', // Same as above
                                  margin: '8px',
                                  padding: '10px',
                                  borderRadius: '4px',
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: '16px',
                                    paddingBottom: '5px',
                                  }}
                                >
                                  {`${key} - ${(
                                    (usersData[key] / usersData.total) *
                                    100
                                  ).toFixed(1)}%`}
                                </Typography>
                                <Gauge
                                  width={140}
                                  height={140}
                                  value={usersData[key]}
                                  valueMin={0}
                                  valueMax={usersData.total}
                                  sx={(theme) => ({
                                    [`& .${gaugeClasses.valueText}`]: {
                                      fontSize: 20,
                                    },
                                    [`& .${gaugeClasses.valueArc}`]: {
                                      fill: '#03aca9',
                                    },
                                    [`& .${gaugeClasses.referenceArc}`]: {
                                      fill: theme.palette.text.disabled,
                                    },
                                  })}
                                />
                              </Box>
                            );
                          })}
                        </Box>
                      </>
                    ) : (
                      <></>
                    )}
                  </Box>
                </Block>
                <Box display="flex" flexDirection="column">
                  {Object.keys(usersData) !== 0 && (
                    <Box width="700px">
                      <Block title="users over time">
                        <LineChart
                          dataset={usersOverTime}
                          xAxis={[{ dataKey: 'x' }]}
                          series={[{ dataKey: 'y' }]}
                          height={300}
                          margin={{ left: 60, right: 30, top: 30, bottom: 30 }}
                          grid={{ vertical: true, horizontal: true }}
                        />
                      </Block>
                    </Box>
                  )}
                </Box>
              </Box>
            )
          )}
        </Box>
      )}
    </>
  );
};

export default Analytics;
