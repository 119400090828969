export const reportsEndpoints = (builder) => {
  return {
    getReports: builder.mutation({
      query: () => ({
        url: '/feedback/reports',
        method: 'get',
      }),
    }),
    getFeedback: builder.mutation({
      query: () => ({
        url: '/feedback',
        method: 'get',
      }),
    }),
    updateReport: builder.mutation({
      query: (query) => ({
        url: '/feedback/reports',
        method: 'post',
        body: { ...query },
      }),
    }),
  };
};
