import { TextWrapCellRenderer, ImageCell } from 'components/SmallComponents';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

const typesDropdown = (props) => {
  const { item, applyValue, focusElementRef } = props;

  const items = [
    'all',
    'not_using_it_enough',
    'too_expensive',
    'found_better_app',
    'technical_issues',
    'other',
  ];

  const handleFilterChange = (event, newValue) => {
    applyValue({ ...item, value: newValue });
  };

  return (
    <FormControl className="ring-0">
      <InputLabel id="demo-simple-select-label">{'types'}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id={'types'}
        value={item.value || 'all'}
        label={'types'}
        onChange={(e) => handleFilterChange(e, e.target.value)}
        size="small"
        sx={{ mt: '5px', width: '140px' }}
      >
        {items.map((item) => {
          return (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

const typeFilterOperator = [
  {
    label: '==',
    value: 'equals',
    getApplyFilterFn: (filterItem) => {
      if (!filterItem.field || !filterItem.value || !filterItem.operator) {
        return null;
      }
      if (filterItem.value == 'all') {
        return (params) => {
          return params !== filterItem.value;
        };
      } else {
        return (params) => {
          return params === filterItem.value;
        };
      }
    },
    InputComponent: typesDropdown,
    InputComponentProps: { type: 'string' },
    getValueAsString: (value) => `${value}`,
  },
];

export const columns = [
  {
    field: 'type',
    headerName: 'type',
    width: 140,
    type: 'string',
    renderCell: TextWrapCellRenderer,
    filterOperators: typeFilterOperator,
  },
  {
    field: 'user',
    headerName: 'user',
    width: 240,
    type: 'string',
  },
  {
    field: 'feedback',
    headerName: 'feedback',
    width: 440,
    renderCell: TextWrapCellRenderer,
  },

  {
    field: 'reported_at',
    headerName: 'reported at',
    width: 150,
    editable: false,
    renderCell: (params) => {
      return (
        <div>{new Date(params.row.reported_at * 1000).toLocaleString()}</div>
      );
    },
    sortComparator: (v1, v2) => Number(v1) - Number(v2),
  },
];
