export const trainingEndpoints = (builder) => {
  return {
    train: builder.mutation({
      query: (data) => ({
        url: '/train',
        method: 'post',
        body: data,
      }),
    }),
    trainFlux: builder.mutation({
      query: (data) => ({
        url: '/trainFlux',
        method: 'post',
        body: data,
      }),
    }),
    trainShuttle: builder.mutation({
      query: (data) => ({
        url: '/trainShuttle',
        method: 'post',
        body: data,
      }),
    }),
  };
};
